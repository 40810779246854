import JSBI from 'jsbi'

import {SolidityType, ChainId} from '../constants'
import {validateSolidityTypeInstance, validateAndParseAddress} from '../utils'

/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
export class DEFAULTCurrency {
  public readonly decimals: number
  public readonly symbol?: string
  public readonly name?: string

  public readonly chainId: ChainId
  public readonly address: string

  /**
   * The only instance of the base class `Currency`.
   */
  public static readonly DEFAULTETHER: DEFAULTCurrency = new DEFAULTCurrency(44787, '0x874069Fa1Eb16D44d622F2e0Ca25eeA172369bC1', 18, 'cUSD', 'Celo Dollar')

  /**
   * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   */
  protected constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8)
    this.decimals = decimals
    this.symbol = symbol
    this.name = name

    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }
}

const DEFAULTETHER = DEFAULTCurrency.DEFAULTETHER
export { DEFAULTETHER }
